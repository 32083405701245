<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Theatervoorstelling</b-card-title>
                <b-card-text>
                    <b-img class="w-50 cardimage" fluid align="right"  v-bind:src="$imagestore2+'images/artikelen/opdehogehogedaken.jpg'"> </b-img>
                    <h4><strong>Theatervoorstelling</strong></h4>
                    <strong><p>Sint & Piet: Op de Hoge Hoge Daken</p></strong>

<p>Daken, schoorstenen, lampjes en een heldere nacht. Ideaal voor Sint en Piet om het dak op te gaan! Maar nieuwsgierige kinderen, kerstverlichting en veel te kleine schoorstenen maken het allemaal niet makkelijk. Kom jij de Pieten helpen? Zing en dans mee op de Hoge Hoge daken!</p>
                </b-card-text>
            </b-card>
            <b-card>
                <b-card-title>Bestel nu uw kaarten</b-card-title>
                <b-card-text>
                    <b-img class="w-50 cardimage" fluid align="right" v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Piet-op-de-laptop.jpg'"> </b-img>
                        <p>Ook benieuwd hoe het gaat aflopen met de hoge daken en kleine schoorstenen? Bestel nu uw kaarten! </p>
                        <p><strong>Theater aan de Slinger Houten</strong></p>
                        <p><a href="https://www.aandeslinger.nl/programma/sint-en-piet-op-de-hoge-hoge-daken-1100-uur" target="_blank" rel="nofollow noopener noreferrer ugc">Zondag 24 november 2024 11:00 uur</a></p>
                        <p><a href="https://www.aandeslinger.nl/programma/sint-en-piet-op-de-hoge-hoge-daken-1530-uur" target="_blank" rel="nofollow noopener noreferrer ugc">Zondag 24 november 2024 15:30 uur</a></p>
                </b-card-text>
            </b-card>
            <b-card>
           
                <b-card-title>Wat we ook nog deden...</b-card-title>
                <b-card-text>
                    <strong>2023: Proef mee uit de Pepernotenprinter!</strong>
                    <p>Sint en Piet geven een rondleiding want het Pietenhuis is helemaal gemoderniseerd. Automatische lampen, robotstofzuigers, muziekinstallatie en een pepernotenprinter moeten ervoor zorgen dat alles dit jaar soepeler verloopt. Maar.. oh nee! Alles gaat stuk! De pieten halen er een monteur bij en Sinterklaas gaat zelf pepernoten bakken.
Gaat het Sinterklaas en de Pieten lukken om genoeg pepernoten te bakken voor 5 december?

Kom langs en Proef mee uit de Pepernotenprinter!</p>
                    <p><strong>2022: Kom naar het Marseplein.</strong> De intocht van Sinterklaas kan natuurlijk niet over het Paasei-plein. Daarom wordt het plein omgedoopt tot het Marseplein. Zul je net zien dat er een grote prijs is gewonnen op het Paasei-plein nummer 7. Maar ja… dát plein bestaat niet meer. Wat nu? Kom ook meezingen, meezoeken én genieten van de intocht!</p>

                    <p><strong>2021: Vaar mee met de Bingoboot.</strong> Midden in de nacht ziet Sinterklaas de Bingoboot aan voor zijn eigen Pakjesboot. Kapitein Dorus weet niet wat hij mee maakt. De Pieten vinden ook een groot pakjesruim maar Kapitein Dorus ziet zijn Bingoprijzen opeens ingepakt in Sinterklaaspapier. Vaar mee en geniet van verwarring, muziek, pepernoten en volop Bingo!</p>

                    <p><strong>2019: Op de Hoge Hoge daken.</strong> Op de Hoge Hoge daken Het huis dat te koop staat heeft iets vreemds, binnen staat een schoen. Wie heeft die gezet. Helaas zitten de Pieten vast in de schoorsteen en lijkt het alsof iemand geen cadeau gaat krijgen.
                        De twee buurmeisjes willen wel helpen maar de buurman aan de andere kant heeft daar geen zin. Hij vind het maar kabaal. Van wie is nu die schoen?</p>
                    <p><silent-box class="galleryitems" :gallery="repertoire"></silent-box></P>
                </b-card-text>
            </b-card>
            <b-card>
            <b-card-title>De Creatievelingen</b-card-title>
                <b-card-text>
                    <b-img class="w-50 cardimage" fluid align="left" v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Cast-en-Crew.jpg'"> </b-img>
                    <p>Het zijn de acteurs, schrijvers, decorbouwers, technici en regisseur die samen de hele productie van de voorstelling draaien. Alles is in eigen productie. In alles wat wij doen staan deze creatievelingen centraal en ook hier zijn Piet & Sint het vertrekpunt waar alles omheen draait.</p>
                    <p>Onze theatergroep heeft een enorme vrijheid doordat alles in eigen beheer is. Dat geeft plezier en gezelligheid die ze daardoor als vanzelf overbrengen op het publiek.</p>
                </b-card-text>
            </b-card>
          
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Voorstelling',
        data: function() {
            return {
                repertoire: [{
                        src: 'https://www.youtube.com/embed/Q6QVuKcLCRw'
                    },
                    {
                        src: 'https://www.youtube.com/embed/fFqakja7TtE'
                    },
                    {
                        src: 'https://www.youtube.com/embed/0yBTKxFPRfE'
                    }
                ]
            }
        }
    }
</script>

<style>

</style>